import styled from 'styled-components';

export const StickyButtonStyle = styled.a`
  display: block;
  position: fixed;
  top: 300px;
  right: 0;
  z-index: 99;
  height: auto;
  padding: 20px;
  font-family: 'Gotham-Bold', arial, sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #fff;
  background: #1fb25a;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  text-decoration: none;
  writing-mode: tb-rl;
  cursor: pointer;
  :hover {
    background: #24a0df;
    color: #fff;
  }
`;
